<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.orderNo"
        placeholder="系统支付单号"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.platformOrderNo"
        placeholder="支付平台单号"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-input
        v-model="listQuery.userkw"
        placeholder="用户关键字(手机号码，姓名，微信昵称)"
        style="width: 260px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.platform"
        placeholder="支付平台"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in PAY_METHOD"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-select
        v-model="listQuery.storeId"
        placeholder="门店"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in storeOption"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-select
        v-model="listQuery.status"
        placeholder="状态"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in PAYMENT_STATUS"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-select
        v-model="listQuery.type"
        placeholder="类型"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option
          v-for="item in PAYMENT_TYPE"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <div style="margin-bottom: 5px">积分充值流水数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        label="订单编号"
        width="220"
        align="center"
        prop="order_num"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="trade_num"
        label="支付平台流水号"
        width="300px"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="platform"
        label="支付平台"
        width="200px"
      >
        <template slot-scope="scope">
          <span>{{ getValue(PAY_METHOD, scope.row.platform) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="金额"
        width="120"
        align="center"
        prop="amount"
      ></el-table-column>
      <el-table-column label="状态" width="120" align="center" prop="status">
        <template slot-scope="scope">
          <span>{{ getValue(PAYMENT_STATUS, scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型" width="120" align="center" prop="type">
        <template slot-scope="scope">
          <span>{{ getValue(PAYMENT_TYPE, scope.row.type) }}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="pay_date"
        label="支付时间"
        width="200px"
      >
        <template slot-scope="scope">
          <span>{{ formatApiDate(scope.row.pay_date) }}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="wx_name"
        label="用户昵称"
        width="200px"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.user.wx_name }}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="mobile"
        label="用户手机号码"
        width="200px"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.user.mobile }}</span>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        width="200px"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import { formatApiDate, handleClipboard } from "@/utils/utils";
import {
  PAY_METHOD,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
  getValue
} from "@/utils/enumUtile";
import { getRechargeOrderList } from "@/api/financial";
import { getStoreList } from "@/api/store";

export default {
  computed: {
    formatApiDate() {
      return formatApiDate;
    },
    PAY_METHOD() {
      return PAY_METHOD;
    },
    PAYMENT_STATUS() {
      return PAYMENT_STATUS;
    },
    PAYMENT_TYPE() {
      return PAYMENT_TYPE;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        orderNo: "", // 系统支付单号
        platformOrderNo: "", //支付平台单号
        platform: "", //支付平台：1-微信，2-支付宝，3-澳门积分，4-系统积分
        userkw: "", //用户关键字(手机号码，姓名，微信昵称)
        storeId: "", //门店ID
        status: "" //状态:1-待支付，2-支付中，3-已支付，4-已作废
      },
      storeOption: [] // 门店
    };
  },
  created() {
    this.getList();
    this.getStoreList();
  },
  watch: {},
  methods: {
    getValue,
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getRechargeOrderList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 查询门店
     */
    getStoreList() {
      const params = {
        page: 1,
        limit: 9999
      };
      getStoreList(params).then(resp => {
        this.storeOption = resp.data.data;
      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
